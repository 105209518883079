<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.deal')" filterable clearable
      :size="size" class="w-100" :filter-method="searchItems" :disabled="disabled">
      <el-option v-for="(item,index) in list" :key="'items-' + index" :label="`${$t('message.deal')} ${$t('message.n')}${item.id}`" :value="item.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      from_filial_id:{
        default: null
      },
      to_filial_id:{
        default: null
      },
      all_client_id:{
        default: null
      },
      query:{
        default: Object
      },
      disabled: {
        default: false
      }
    },
    data() {
      return {
        selected: null,
        list: [],
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      },
      watchFilials:{
        handler: function(val) {
            let query = {...this.query ,from_filial_id: this.from_filial_id, to_filial_id: this.to_filial_id, all_client_id: this.all_client_id};
            this.list = [];
           if(this.from_filial_id){
              this.selected = null;
              this.updateInventory(query)
                .then(res => {
                    this.list = JSON.parse(JSON.stringify(this.inventoryItems));
                });
           }
        },
        immediate: true
      },
     
    },
  
    computed: {
      ...mapGetters({
        inventoryItems: 'deals/inventory',
      }),
      watchFilials(){
        return this.from_filial_id + this.to_filial_id + this.all_client_id;
      }
    },
    methods: {
      ...mapActions({
        updateInventory: 'deals/inventory',
      }),
      searchItems: _.debounce(
        function(val) {
          if(val){
            this.updateInventory({...this.query, search: val}).then(res => {
                this.list = JSON.parse(JSON.stringify(this.inventoryItems));
            }); 
          }else{
            this.updateInventory(this.query).then(res => {
                this.list = JSON.parse(JSON.stringify(this.inventoryItems));
            }); 
          }
        }, 500),

      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
